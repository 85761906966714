import React from 'react';

import { useLocation } from 'react-router-dom';

import { useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';

import { makeStyles } from 'tss-react/mui';

import { PartnerInfo } from '@ha/api/v2/types/UserInformation';
import { Divider, Typography } from '@hbf/dsl/core';

import { useIntl } from 'ha/i18n';
import { getIsPartnerUniversityStudent } from 'ha/modules/AuthLogic/selectors';

import { ImgixSize } from 'ha/modules/Image/constants';
import { Imgix } from 'ha/modules/Image/Imgix';

import { PARTNERSHIP_TYPE_TRANSLATIONS } from '../../constants';

const useStyles = makeStyles()(theme => ({
  universityPartnerContainer: {
    display: 'flex',
    gap: theme.utils.spacing('ref/spacing/3'),
  },
  universityLogo: {
    display: 'flex',
    alignItems: 'center',
  },
  partnerInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  partnerIconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  partnerIcon: {
    maxHeight: '60px',
    maxWidth: '130px',
    width: 'auto',
    height: 'auto',
  },
  divider: {
    maxWidth: 'max-content',
  },
}));

interface Props {
  partnerInfo: PartnerInfo;
}

export const CoBrandedLogo = ({ partnerInfo }: Props) => {
  const { classes, theme } = useStyles();

  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));
  const isPartnerUniversityStudent = useSelector(getIsPartnerUniversityStudent);

  const { T, urlResolver } = useIntl();

  const location = useLocation();

  const universityLink = isPartnerUniversityStudent
    ? urlResolver.getMyUniversityUrl()
    : urlResolver.absolutizePath(location.pathname);

  return (
    <div className={classes.universityPartnerContainer}>
      <div className={classes.universityLogo}>
        <a
          href={universityLink}
          className={classes.partnerIconContainer}
          aria-label={partnerInfo.name}
          title="University logo"
          rel="noreferrer"
        >
          <Imgix
            src={partnerInfo.iconUrl}
            className={classes.partnerIcon}
            htmlAttributes={{
              alt: partnerInfo.name,
            }}
            width={ImgixSize.xxxsmall.w}
          />
        </a>
      </div>

      {isLargerThanMd && (
        <React.Fragment>
          <Divider orientation="vertical" className={classes.divider} />
          <div className={classes.partnerInfo}>
            <Typography variant="caption/regular">
              {T(PARTNERSHIP_TYPE_TRANSLATIONS[partnerInfo.partnershipType])}
            </Typography>
            <Typography variant="body/sm-semibold">
              {T('navbar.advertiser_logo.subtitle')}
            </Typography>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
